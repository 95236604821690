import React, { useEffect, useState } from "react";
import { useLanguageStore } from "../../hooks/useLanguage";
import Modal from "react-bootstrap/Modal";
import { FaSave, FaTimes, FaUserPlus, FaUsers } from "react-icons/fa";
import { getCookie } from "../../libs/utils";
import toast from "react-hot-toast";
import {Window} from '@progress/kendo-react-dialogs';

import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
} from "@progress/kendo-react-form";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import { AutoComplete, ComboBox } from "@progress/kendo-react-dropdowns";

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const emailValidator = (value) =>
  emailRegex.test(value) ? "" : "Please enter a valid email.";
const EmailInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div className="k-form-field-wrap">
      <Input {...others} labelClassName={"k-form-label"} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const RealModalBody = (props) => {
  const { t } = useLanguageStore();
  const {
    show,
    onShow,
    onHide,
    theme,
    selectedProfile,
    getPermissionProfiles,
    getPermissionProfiles_api,
    allMembers,
  } = props;
  const [userProfile, setUserProfile] = useState(selectedProfile);
  const [newUser, setNewUser] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [existingMember, setExistingMember] = useState(null);
  const handleSubmit = (dataItem) => {
    toast.custom(JSON.stringify(dataItem, null, 2));
    setNewUser(dataItem);
    //   add a new key to dataItem  called permissionprofile with a value of selectedprofile to the dataItem
    dataItem.userProfile = userProfile;
    const selectedMember = dataItem.selectMember;
    console.log("selectedMember", selectedMember);

    addUserToProfile_api(dataItem);
  };

  const addUserToProfile_api = (dataItem) => {
    return new Promise((resolve, reject) => {
      const requestBody = {
        ...dataItem,
        selectedMember: existingMember,
      };

      fetch(`/apiv2/add_user_to_profile/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: JSON.stringify({
          requestBody,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((data) => {
              throw new Error(data.error || "Unknown error");
            });
          }
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            resolve(data);
            getPermissionProfiles(getPermissionProfiles_api);
            onHide();
          } else {
            console.log("data.error", data.error);
            toast.error(
              "Failed to update permission profile permissions: " + data.error
            );
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error(
            "Invalid details, please check that credentials are unique"
          );
        });
    });
  };

  const handleExistingMemberChange = (e) => {
    const selectedMember = allMembers.find((member) => {
      const memberString = member.Names
        ? `${member.Names} ${member.Surname} ${member.email} ${member.username}`
        : `${member.username} ${member.Surname} ${member.email}`;
      return memberString === e.value;
    });
    if (selectedMember) {
      setExistingMember({
        Names: selectedMember.Names || selectedMember.first_name,
        Surname: selectedMember.Surname || selectedMember.last_name,
        email: selectedMember.email,
        id: selectedMember.id,
      });
    }
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{
          backgroundColor:
            theme === "dark" ? "rgb(24,36,51)" : "rgba(124,116,200,0.1)",
          padding: "20px",
        }}
      >
        <div
          className="row"
          style={{
            padding: "5px",
            backgroundColor: theme === "dark" ? "rgb(33,41,57)" : "",
            borderRadius: "5px",
            boxShadow: "10px",
          }}
        >
          <div className="d-flex col-lg-12">
            <h2 style={{ marginTop: "5px" }}>
              {t("Add User to")} {selectedProfile}
            </h2>
            <FaTimes
              size={20}
              className={"ms-auto cursor-pointer"}
              onClick={onHide}
            />
          </div>
        </div>
        <div className="pt-0">
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
              <FormElement
                style={{
                  maxWidth: 650,
                  color: theme === "dark" ? "white" : "black",
                }}
              >
                <fieldset className={"k-form-fieldset"}>
                  <legend
                    className={"k-form-legend"}
                    style={{
                      color: theme === "dark" ? "rgb(116,116,126)" : "",
                    }}
                  >
                    {t("Please fill in the fields:")}
                  </legend>
                  <FieldWrapper>
                    <div
                      className="k-form-field-wrap"
                      style={{ color: theme === "dark" ? "white" : "black" }}
                    >
                      <Field
                        name={"isMember"}
                        component={Checkbox}
                        // labelClassName={"k-form-label"}
                        label={t("Is the user a church member?")}
                        onChange={(e) => setIsMember(e.value)}
                      />
                    </div>
                  </FieldWrapper>
                  {isMember && (
                    <AutoComplete
                      style={{
                        width: "300px",
                        margin: "10px",
                        padding: "5px",
                        verticalAlign: "middle",
                        zIndex: 1000,
                      }}
                      data={allMembers.map((member) =>
                        member.id
                          ? member.username + " " +
                            member.Names +
                            " " +
                            member.Surname +
                            " " +
                            member.email


                          : member.username +
                            " " +
                            member.last_name +
                            " " +
                            member.email
                      )} //
                      placeholder={t(
                        "... type member's name to search Member "
                      )}
                      onChange={(e) => handleExistingMemberChange(e)}
                    />
                  )}
                  <FieldWrapper>
                    <div
                      className="k-form-field-wrap"
                      style={{
                        color: theme === "dark" ? "rgb(116,116,126)" : "",
                      }}
                    >
                      <Field
                        name={"userName"}
                        component={Input}
                        labelClassName={"k-form-label"}
                        label={t("Username")}
                      />
                    </div>
                  </FieldWrapper>
                  {!isMember && (
                    <>
                      <FieldWrapper>
                        <div
                          className="k-form-field-wrap"
                          style={{
                            color: theme === "dark" ? "rgb(116,116,126)" : "",
                          }}
                        >
                          <Field
                            name={"firstName"}
                            component={Input}
                            labelClassName={"k-form-label"}
                            label={t("First Name")}
                          />
                        </div>
                      </FieldWrapper>
                      <FieldWrapper>
                        <div
                          className="k-form-field-wrap"
                          style={{
                            color: theme === "dark" ? "rgb(116,116,126)" : "",
                          }}
                        >
                          <Field
                            name={"lastName"}
                            component={Input}
                            labelClassName={"k-form-label"}
                            label={t("Last Name")}
                          />
                        </div>
                      </FieldWrapper>

                      <FieldWrapper>
                        <div
                          style={{
                            color: theme === "dark" ? "rgb(116,116,126)" : "",
                          }}
                        >
                          <Field
                            name={"userEmail"}
                            type={"email"}
                            component={EmailInput}
                            label={t("Email")}
                            validator={emailValidator}
                          />
                        </div>
                      </FieldWrapper>
                    </>
                  )}
                  <FieldWrapper>
                    <div
                      className="k-form-field-wrap"
                      style={{
                        color: theme === "dark" ? "rgb(116,116,126)" : "",
                      }}
                    >
                      <Field
                        name={"UserPassword"}
                        component={Input}
                        labelClassName={"k-form-label"}
                        label={t("Password")}
                      />
                    </div>
                  </FieldWrapper>

                  <FieldWrapper>
                    <div
                      className="k-form-field-wrap"
                      style={{ color: theme === "dark" ? "white" : "black" }}
                    >
                      <Field
                        name={"isActive"}
                        component={Checkbox}
                        label={t("is Active")}
                      />
                    </div>
                  </FieldWrapper>
                </fieldset>
                <div className="k-form-buttons">
                  <Button disabled={!formRenderProps.allowSubmit}>
                    {t("Submit")}
                  </Button>
                </div>
              </FormElement>
            )}
          />
        </div>
      </div>
    </>
  );
};

export const AddNewUserModal = (props) => {
  let {
    show,
    onShow,
    onHide,
    theme,
    selectedProfile,
    user,
    getPermissionProfiles,
    getPermissionProfiles_api,
    allMembers,
  } = props;

  return (
    <>
      <>
      {show && (
        <Window
          title={`Add User to ${selectedProfile}`}
          onClose={onHide}
          width={800}
          height={600}
          modal={true}
          style={{
            zIndex: 100000,
            backgroundColor: theme === "dark" ? "#182433" : "#ffffff",
            borderRadius: "10px",
          }}
        >
          <RealModalBody {...props} />
        </Window>
      )}
    </>
      {/*<div className="container-fluid">*/}
      {/*  <div className="row">*/}
      {/*    <div className="col-lg-6">*/}
      {/*      <div className="card-container">*/}
      {/*        <div className="card">*/}
      {/*          {show && (*/}
      {/*            <div*/}
      {/*              show={show}*/}
      {/*              onHide={onHide}*/}
      {/*              selectedProfile={selectedProfile}*/}
      {/*              user={user}*/}
      {/*              allMembers={allMembers}*/}
      {/*              style={{ overflow: "visible" }}*/}
      {/*            >*/}
      {/*              {show && <RealModalBody {...props} />}*/}
      {/*            </div>*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
};
