import React, { useEffect, useState } from "react";
import { FaSave, FaTrash, FaUserPlus } from "react-icons/fa";
import { AddAdditionalFamilyMemberModal } from "./modals/AddAdditionalFamilyMemberModal";
import { getCookie } from "../libs/utils";
import { usePermissions } from "../hooks/PermissionContext";
import { Button } from "@progress/kendo-react-buttons";
import toast from "react-hot-toast";
import { useLanguageStore } from "../hooks/useLanguage";

export default function FamilyTab(props) {
  const { t } = useLanguageStore();
  const permissions = usePermissions();
  const {
    show,
    onShow,
    onHide,
    user,
    selectedFamily,
    setSelectedFamily,
    theme,
    handleShowMemberModal,
    selectedMemberChanged,
    setSelectedMemberChanged,
    getFamily,
    getMembers,
  } = props;

  const [showMemberModal, setShowMemberModal] = useState(false);
  const [localSelectedMember, setLocalSelectedMember] = useState(null);
  const [family, setFamily] = useState(props.selectedFamily);
  const [selectedMember, setSelectedMember] = useState(null);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    setFamily(props.selectedFamily);
  }, [props.selectedFamily]);

  useEffect(() => {
    if (showMemberModal) {
      getFamily();
    }
  }, [showMemberModal]);

  const MemberTypeDisplay = ({ member }) => {
    if (member?.member_type === "Head") {
      return (
        <h4 className={"badge bg-success-lt mb-0 h4"}>
          {t(member?.member_type)}
        </h4>
      );
    } else if (member?.member_type === "Spouse") {
      return (
        <h4 className={"badge bg-warning-lt mb-0 h4"}>
          {t(member?.member_type)}
        </h4>
      );
    }
    return (
      <h4 className={"badge bg-info-lt mb-0 h4"}>{t(member?.member_type)}</h4>
    );
  };

  const memberClicked = (member) => {
    selectedMemberChanged(member);
    handleShowMemberModal();
  };

  const onMemberModalHide = () => {
    setShowMemberModal(false);
    getFamily();
  };

  const onAddMember = () => {
    let random_id = Math.floor(Math.random() * 1000000);
    let new_member = {
      id: random_id,
      first_name: "",
      last_name: "",
      dob: "",
      type: "Child",
      ftype: "Head",
      familyid: null,
    };
    setMembers([...members, new_member]);
    setSelectedMember(new_member);
    setShowMemberModal(true);
  };

  const handleDeleteFamily = (family_id) => {
    if (window.confirm("Are you sure you want to delete the entire Family?")) {
      deleteFamily_api(family_id).then((data) => {
        if (data?.success) {
          toast.success(t("Family deleted successfully"));
          getMembers();
          onHide();
          setSelectedFamily(null);
        } else {
          toast.error(t("Failed to delete family"));
        }
      });
    }
  };

  const deleteFamily_api = async (family_id) => {
    const response = await fetch(`/apiv2/family/${family_id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
        family_id: family_id,
      },
    });
    return await response.json();
  };

  return (
    <>
      {permissions?.permissions?.includes("modify_members") && (
        <AddAdditionalFamilyMemberModal
          show={showMemberModal}
          onHide={onMemberModalHide}
          members={members}
          setMembers={setMembers}
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
          selectedFamily={selectedFamily}
          setSelectedFamily={setSelectedFamily}
        />
      )}
      {permissions?.permissions?.includes("modify_members") && (
        <div className="row">
          <div className="col-lg-12">
            <button className={" btn btn-outline-primary"}>
              <FaUserPlus size={20} />
              <span onClick={onAddMember} className={"ms-2"}>
                {t("Add Member")}
              </span>
            </button>
          </div>
        </div>
      )}
      {permissions?.permissions?.includes("delete_family") && (
        <div
          className="row"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <div className="col-lg-12">
            <Button
              className="bg-danger-lt"
              onClick={(event) => {
                handleDeleteFamily(selectedFamily?.id);
              }}
            >
              <FaTrash /> {t("Delete the entire Family")}
            </Button>
          </div>
          <div className="col-12 d-flex mt-2">
            <div className="bg-primary-lt p-2">
              {t("Home Address")}: {selectedFamily?.street_address}
            </div>
          </div>
        </div>
      )}
      <div className="row mt-2">
        {selectedFamily?.members?.map((member, memberIndex) => {
          return (
            <>
              <div className="col-lg-4 mt-3">
                <div
                  className="card bg-primary-lt cursor-pointer"
                  onClick={() => memberClicked(member)}
                >
                  <MemberTypeDisplay member={member} />
                  <div className="card-body p-3 pt-1">
                    <table className="table table-borderless table-sm">
                      <tbody>
                        <tr>
                          <td>{t("Member No")}</td>
                          <td>{member?.UniqueLidNo}</td>
                        </tr>
                        <tr>
                          <td>{t("Names")}</td>
                          <td>{member?.Names}</td>
                        </tr>
                        <tr>
                          <td>{t("Full Names")}</td>
                          <td>{member?.FullNames}</td>
                        </tr>
                        <tr>
                          <td>{t("Surname")}</td>
                          <td>{member?.Surname}</td>
                        </tr>
                        <tr>
                          <td>{t("DOB")}</td>
                          <td>
                            {member?.BirthDate}{" "}
                            {member?.BirthDate && `(${member?.age})`}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}
