import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {AutoComplete, ComboBox, DropDownList} from "@progress/kendo-react-dropdowns";
import {Checkbox, Input, Switch} from "@progress/kendo-react-inputs";
import {Button} from "@progress/kendo-react-buttons";
import {getCookie} from "../libs/utils";
import data from "bootstrap/js/src/dom/data";
import {AddNewUserModal} from "./modals/AddNewUserModal";
import {FaPlus, FaPlusCircle, FaTrash} from "react-icons/fa";
import {Spinner} from "react-bootstrap";
import {Window} from '@progress/kendo-react-dialogs';
import "../styles/styles.css";
import {
    Form,
    Field,
    FormElement,
    FieldWrapper,
} from "@progress/kendo-react-form";
import {usePermissions} from "../hooks/PermissionContext";
import {useLanguageStore} from "../hooks/useLanguage";
import AddProfileModalBody from "./modals/AddProfileModalBody";

const PermissionProfiles = (props) => {
    const {t} = useLanguageStore();
    const perm = usePermissions();
    const {
        loggedInUser,
        theme,
        getPermissionProfiles,
        permissions,
        allMembers,
    } = props;

    const [selectedProfile, setSelectedProfile] = useState(null);
    const [profileSelected, setProfileSelected] = useState(false);
    const [showAddNewUserModal, setShowAddNewUserModal] = useState(false);
    const [showAddProfileModal, setShowAddProfileModal] = useState(false);
    const [newProfile, setNewProfile] = useState(null);
    const [showAddProfile, setShowAddProfile] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSelectedProfile(null);
        setProfileSelected(false);
        setShowAddNewUserModal(false);
        getPermissionProfiles(getPermissionProfiles_api);
    }, []); // Empty dependency array ensures this runs only once

    const getPermissionProfiles_api = () => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/api_permissions`, {})
                .then((res) => {
                    if (!res.ok) {
                        return res.text().then((text) => {
                            throw new Error(t("Failed to load permission profiles: "));
                        });
                    }
                    return res.json();
                })

                // .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data);
                    } else {
                        reject(new Error(t("Failed to load permission profiles: ")));
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    const handleSubmit = (dataItem) => {
        toast.custom(JSON.stringify(dataItem, null, 2));
        setNewProfile(dataItem);
        addNewProfile_api(dataItem);
        setShowAddProfile(false);
    };

    const addNewProfile_api = (dataItem) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/add_permission_profile/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
                body: JSON.stringify({
                    profileName: dataItem.profileName,
                    // profileId: permissions.find(profile => profile.name === selectedProfile).id,
                    // permissionId: permissionId,
                    // isChecked: isChecked,
                }),
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data);
                        getPermissionProfiles(getPermissionProfiles_api);
                    } else {
                        reject(
                            new Error(t("Failed to update permission profile permissions"))
                        );
                    }
                });
        }).catch((error) => {
            toast.error(t("Failed to update permission: ") + error.message);
        });
    };

    const selectedProfilePermissions =
        permissions.find((profile) => profile.name === selectedProfile)
            ?.permissions || [];

    const handleChangePermissionProfile = (event) => {
        setSelectedProfile(event.target.value);
        setProfileSelected(true);
    };

    const handleDefaultChecked = (permission) => {
        return selectedProfilePermissions.some(
            (selectedPermission) => selectedPermission.id === permission.id
        );
    };

    const handleCheckboxChange = (event, permissionId) => {
        const isChecked = event.target.value;

        // Make an API call to update the database
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/update_permission_profile_permissions/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
                body: JSON.stringify({
                    profileName: selectedProfile,
                    profileId: permissions.find(
                        (profile) => profile.name === selectedProfile
                    ).id,
                    permissionId: permissionId,
                    isChecked: isChecked,
                }),
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data);
                        getPermissionProfiles(getPermissionProfiles_api);
                    } else {
                        reject(
                            new Error(t("Failed to update permission profile permissions"))
                        );
                    }
                });
        }).catch((error) => {
            toast.error(t("Failed to update permission: ") + error.message);
        });
    };

    const handleUserActiveChange = (event, userId) => {
        const isActive = event.target.value;
        activate_user_api(userId, isActive)
            .then((data) => {
                toast.success(t("User permissions updated successfully"));
                // getPermissionProfiles(getPermissionProfiles_api)
            })
            .catch((err) => {
                toast.error(
                    t("An error occurred while updating user permissions") + err.message
                );
            });
    };

    const activate_user_api = (userId, isActive) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/activate_user_api/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
                body: JSON.stringify({
                    profileName: selectedProfile,
                    userId: userId,
                    isActive: isActive,
                }),
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data);
                        getPermissionProfiles(getPermissionProfiles_api);
                    } else {
                        reject(
                            new Error(t("Failed to update permission profile permissions"))
                        );
                    }
                });
        }).catch((error) => {
            toast.error(t("Failed to update permission: ") + error.message);
        });
    };

    const handleDeleteUser = (event, userId) => {
        delete_user_api(userId)
            .then((data) => {
                toast.success(t("User deleted successfully"));
                getPermissionProfiles(getPermissionProfiles_api);
            })
            .catch((err) => {
                toast.error(t("An error occurred while deleting user") + err.message);
            });
    };

    const delete_user_api = (userId) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/add_user_to_profile/`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
                body: JSON.stringify({
                    profileName: selectedProfile,
                    userId: userId,
                }),
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data);
                        getPermissionProfiles(getPermissionProfiles_api);
                    } else {
                        reject(
                            new Error(t("Failed to update permission profile permissions"))
                        );
                    }
                });
        }).catch((error) => {
            toast.error(t("Failed to update permission: ") + error.message);
        });
    };

    const handleDeleteProfile = (event, profileId) => {
        delete_profile_api(profileId)
            .then((data) => {
                toast.success(t("Profile deleted successfully"));
                getPermissionProfiles(getPermissionProfiles_api);
            })
            .catch((err) => {
                toast.error(
                    t("An error occurred while deleting profile") + err.message
                );
            });
    };
    // const handleSelectAll = () => {
    //     const visiblePermissions = filterPermissionsByCategories([
    //     "member",
    //     "note",
    //     "message",
    //     "visit",
    //     "group",
    //     "family",
    // ]).filteredPermissions;
    //
    // visiblePermissions.forEach((permission) => {
    //     handleCheckboxChange(
    //         { target: { value: true } }, // Simulate a checked event
    //         permission.id
    //     );
    // });
    // }
    const handleSelectAll = () => {
        setLoading(true)
        const selectedProfileObj = permissions.find(
            (profile) => profile.name === selectedProfile
        );

        if (!selectedProfileObj) {
            console.error("Selected profile not found");
            return;
        }
        const allpermissions = selectedProfileObj.all_permissions || [];
        const payload = {
            profileName: selectedProfile,
            profileId: permissions.find(
                (profile) => profile.name === selectedProfile
            ).id,
            permissions: allpermissions.map((perm) => ({
                permissionId: perm.id,
                isChecked: true,
            })),
        };

        fetch(`/apiv2/update_permission_profile_permissions_bulk/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
            body: JSON.stringify(payload),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    toast.success(t("Permissions updated successfully."));
                    setLoading(false)
                    getPermissionProfiles(getPermissionProfiles_api);
                } else {
                    toast.error(t("Failed to update permissions."));
                }
            })
            .catch((error) => {
                toast.error(t("Failed to update permission: ") + error.message);
            });
    };

    const handleRemoveAll = () => {
        setLoading(true)
        const selectedProfileObj = permissions.find(
            (profile) => profile.name === selectedProfile
        );

        if (!selectedProfileObj) {
            console.error("Selected profile not found");
            return;
        }
        const allpermissions = selectedProfileObj.all_permissions || [];

        const payload = {
            profileName: selectedProfile,
            profileId: permissions.find(
                (profile) => profile.name === selectedProfile
            ).id,
            permissions: allpermissions.map((perm) => ({
                permissionId: perm.id,
                isChecked: false,
            })),
        };

        fetch(`/apiv2/update_permission_profile_permissions_bulk/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
            body: JSON.stringify(payload),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    toast.success(t("Permissions updated successfully."));
                    getPermissionProfiles(getPermissionProfiles_api);
                    setLoading(false)
                } else {
                    toast.error(t("Failed to update permissions."));
                }
            })
            .catch((error) => {
                toast.error(t("Failed to update permission: ") + error.message);
            });
    };

    const delete_profile_api = (profileId) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/delete_permission_profile/`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
                body: JSON.stringify({
                    profileId: profileId,
                }),
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data);
                        getPermissionProfiles(getPermissionProfiles_api);
                    } else {
                        reject(
                            new Error(t("Failed to update permission profile permissions"))
                        );
                    }
                });
        }).catch((error) => {
            toast.error(t("Failed to update permission: ") + error.message);
        });
    };

    const filterPermissionsByCategories = (categories) => {
        const categorizedPermissions = new Set();
        const filteredPermissions =
            permissions
                .find((profile) => profile.name === selectedProfile)
                ?.all_permissions.filter((permission) => {
                const isCategorized = categories.some((category) =>
                    permission.description
                        .toLowerCase()
                        .includes(category.toLowerCase())
                );
                if (isCategorized) {
                    categorizedPermissions.add(permission.id);
                }
                return isCategorized;
            }) || [];
        return {filteredPermissions, categorizedPermissions};
    };

    const getUncategorizedPermissions = (categorizedPermissions) => {
        return (
            permissions
                .find((profile) => profile.name === selectedProfile)
                ?.all_permissions.filter(
                (permission) => !categorizedPermissions.has(permission.id)
            ) || []
        );
    };
    console.log("permissions", permissions);

    return (
        <>
            {showAddNewUserModal &&
                perm?.permissions?.includes("modify_permissions") && (
                    <AddNewUserModal
                        show={showAddNewUserModal}
                        onHide={() => setShowAddNewUserModal(false)}
                        theme={theme}
                        user={loggedInUser}
                        selectedProfile={selectedProfile}
                        getPermissionProfiles={getPermissionProfiles}
                        getPermissionProfiles_api={getPermissionProfiles_api}
                        allMembers={allMembers}
                    />
                )}
            {loading &&
                <div style={{
                    position: "absolute",
                    top: "30px",
                    left: 0,
                    width: "100%",
                    height: "70%",
                    display: "flex",

                    justifyContent: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",  // Semi-transparent background
                    zIndex: 9999  // Ensure it's above everything
                }}>
                    <Spinner
                        animation="border"
                        style={{
                            width: "4rem",
                            height: "4rem",
                            borderWidth: "0.6em",
                        }}
                        role="status"
                        variant="primary"
                    >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }
            {!showAddNewUserModal && (
                <div className="container-fluid">
                    <div className="card p-3">
                        <div className="d-flex flex-wrap align-items-center gap-3 mb-3">
                            <DropDownList
                                // className="form-select"
                                style={{
                                    width: "300px", height: "36px", // Tighten height
                                    fontSize: "14px"
                                }}
                                data={permissions.map((permission) => permission.name)}
                                defaultValue={t("Select a permission profile")}
                                onChange={handleChangePermissionProfile}
                            />
                            {perm?.permissions?.includes("modify_permissions") && (
                                <button
                                    onClick={() => setShowAddProfile(true)}
                                    className="btn btn-outline-primary d-flex align-items-center gap-2"
                                >
                                    <FaPlusCircle/>

                                    {t("Add Permission Profile")}
                                </button>
                            )}
                        </div>
                        {showAddProfile && perm?.permissions?.includes("modify_permissions") && (
                            <Window
                                title={t("Create New Profile")}
                                onClose={() => setShowAddProfile(false)}
                                modal={true}
                                width={600}
                                height={300}
                                style={{
                                    zIndex: 100000,
                                    backgroundColor: theme === "dark" ? "#182433" : "#ffffff",
                                    borderRadius: "10px",
                                }}
                            >
                                <AddProfileModalBody
                                    onSubmit={handleSubmit}
                                    theme={theme}
                                    t={t}
                                />
                            </Window>
                        )}

                        {profileSelected && selectedProfile && (
                            <div className="mt-4 p-3 border rounded">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <h4 className="mb-0">{t("Permissions for")}: {selectedProfile}</h4>
                                    {perm.permissions.includes("modify_permissions") && (
                                        <button
                                            className="btn btn-danger"
                                            onClick={(event) => handleDeleteProfile(event, selectedProfile)}
                                        >
                                            {t("Delete this Permission Profile")}
                                        </button>
                                    )}
                                </div>

                                <div className="row">
                                    {/* Left column - Permissions */}
                                    <div className="col-md-6 mb-3">
                                        <div
                                            className="p-3 border rounded overflow-auto"
                                            style={{height: "600px"}}
                                        >
                                            <ul
                                                // className="overflow-scroll"
                                                className="list-unstyled"
                                            >
                                                <div
                                                    style={{borderBottom: "thin solid rgba(124,116,200,0.5)"}}
                                                >
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "space-between", // Pushes the buttons to the right
                                                        alignItems: "center", // Vertically centers the heading and buttons
                                                        width: "100%" // Ensures it takes up the full width
                                                    }}>
                                                        <h2>{t("Members")}</h2>
                                                        <div style={{display: "flex", gap: "10px"}}>
                                                            <Button
                                                                size={"medium"}
                                                                themeColor={theme === "dark" ? "success" : "success"}
                                                                fillMode={"outline"}
                                                                rounded={"medium"}
                                                                onClick={handleSelectAll}
                                                            >{t("Select All")}
                                                            </Button>
                                                            <Button
                                                                size={"medium"}
                                                                themeColor={theme === "dark" ? "warning" : "warning"}
                                                                fillMode={"outline"}
                                                                rounded={"medium"}
                                                                onClick={handleRemoveAll}
                                                            >{t("Remove All")}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    {filterPermissionsByCategories([
                                                        "member",
                                                        "note",
                                                        "message",
                                                        "visit",
                                                        "group",
                                                        "family",
                                                    ]).filteredPermissions.map((permission, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <li
                                                                style={{
                                                                    marginBottom: "10px",
                                                                    padding: "10px",
                                                                    backgroundColor:
                                                                        index % 2 === 0
                                                                            ? "rgba(124,116,200,0.1)"
                                                                            : "rgba(124,116,200,0)",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                {t(permission.description)}
                                                                <Checkbox
                                                                    style={{
                                                                        marginLeft: "20px",
                                                                    }}
                                                                    value={permission.id}
                                                                    disabled={
                                                                        !perm?.permissions?.includes("modify_permissions")
                                                                    }
                                                                    checked={handleDefaultChecked(permission)}
                                                                    onChange={(event) =>
                                                                        handleCheckboxChange(event, permission.id)
                                                                    }
                                                                />
                                                            </li>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div
                                                    style={{borderBottom: "thin solid rgba(124,116,200,0.5)"}}
                                                >
                                                    {console.log("permission", permissions)}
                                                    <h2>{t("User Management")}</h2>
                                                    {filterPermissionsByCategories([
                                                        "user",
                                                        "permission",
                                                    ]).filteredPermissions.map((permission, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <li
                                                                style={{
                                                                    marginBottom: "10px",
                                                                    padding: "10px",
                                                                    backgroundColor:
                                                                        index % 2 === 0
                                                                            ? "rgba(124,116,200,0.1)"
                                                                            : "rgba(124,116,200,0)",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                {t(permission.description)}
                                                                <Checkbox
                                                                    style={{
                                                                        marginLeft: "20px",
                                                                    }}
                                                                    value={permission.id}
                                                                    disabled={
                                                                        !perm?.permissions?.includes("modify_permissions")
                                                                    }
                                                                    checked={handleDefaultChecked(permission)}
                                                                    onChange={(event) =>
                                                                        handleCheckboxChange(event, permission.id)
                                                                    }
                                                                />
                                                            </li>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div
                                                    style={{borderBottom: "thin solid rgba(124,116,200,0.5)"}}
                                                >
                                                    <h2>{t("General Management")}</h2>
                                                    {filterPermissionsByCategories([
                                                        "event",
                                                    ]).filteredPermissions.map((permission, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <li
                                                                style={{
                                                                    marginBottom: "10px",
                                                                    padding: "10px",
                                                                    backgroundColor:
                                                                        index % 2 === 0
                                                                            ? "rgba(124,116,200,0.1)"
                                                                            : "rgba(124,116,200,0)",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                {t(permission.description)}
                                                                <Checkbox
                                                                    style={{
                                                                        marginLeft: "20px",
                                                                    }}
                                                                    value={permission.id}
                                                                    disabled={
                                                                        !perm?.permissions?.includes("modify_permissions")
                                                                    }
                                                                    checked={handleDefaultChecked(permission)}
                                                                    onChange={(event) =>
                                                                        handleCheckboxChange(event, permission.id)
                                                                    }
                                                                />
                                                            </li>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div
                                                    style={{borderBottom: "thin solid rgba(124,116,200,0.5)"}}
                                                >
                                                    <h2>{t("Finance")}</h2>
                                                    {filterPermissionsByCategories([
                                                        "transaction",
                                                        "account",
                                                        "income",
                                                        "expense",
                                                        "balance",
                                                    ]).filteredPermissions.map((permission, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <li
                                                                style={{
                                                                    marginBottom: "10px",
                                                                    padding: "10px",
                                                                    backgroundColor:
                                                                        index % 2 === 0
                                                                            ? "rgba(124,116,200,0.1)"
                                                                            : "rgba(124,116,200,0)",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                {t(permission.description)}
                                                                <Checkbox
                                                                    style={{
                                                                        marginLeft: "20px",
                                                                    }}
                                                                    value={permission.id}
                                                                    disabled={
                                                                        !perm?.permissions?.includes("modify_permissions")
                                                                    }
                                                                    checked={handleDefaultChecked(permission)}
                                                                    onChange={(event) =>
                                                                        handleCheckboxChange(event, permission.id)
                                                                    }
                                                                />
                                                            </li>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div
                                                    style={{borderBottom: "thin solid rgba(124,116,200,0.5)"}}
                                                >
                                                    <h2>{t("Other")}</h2>
                                                    {getUncategorizedPermissions(
                                                        filterPermissionsByCategories([
                                                            "member",
                                                            "note",
                                                            "message",
                                                            "user",
                                                            "permission",
                                                            "event",
                                                            "visit",
                                                            "group",
                                                            "family",
                                                            "transaction",
                                                            "account",
                                                            "income",
                                                            "expense",
                                                            "balance",
                                                        ]).categorizedPermissions
                                                    ).map((permission, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <li
                                                                style={{
                                                                    marginBottom: "10px",
                                                                    padding: "10px",
                                                                    backgroundColor:
                                                                        index % 2 === 0
                                                                            ? "rgba(124,116,200,0.1)"
                                                                            : "rgba(124,116,200,0)",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                {t(permission.description)}
                                                                <Checkbox
                                                                    style={{marginLeft: "20px"}}
                                                                    value={permission.id}
                                                                    disabled={
                                                                        !perm?.permissions?.includes("modify_permissions")
                                                                    }
                                                                    checked={handleDefaultChecked(permission)}
                                                                    onChange={(event) =>
                                                                        handleCheckboxChange(event, permission.id)
                                                                    }
                                                                />
                                                            </li>
                                                        </div>
                                                    ))}
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                    {/*        right side */}
                                    {profileSelected && (
                                        <div className="col-md-6">
                                            <div className="p-3 border rounded" style={{height: "600px", overflowY: "auto"}}>
                                                <h4>
                                                    {t("Users with Permissions for ")} {selectedProfile}
                                                </h4>

                                                {perm?.permissions?.includes("modify_permissions") && (
                                                    <div
                                                        className="card mb-3"

                                                    >
                                                        <div className="m-2">
                                                            <Button onClick={() => setShowAddNewUserModal(true)}>
                                                                {t("Add a User with")} <strong>{selectedProfile}</strong>{" "}
                                                                {t("profile")}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}

                                                <div>
                                                    <ul className="list-unstyled">
                                                        {permissions
                                                            .find((profile) => profile.name === selectedProfile)
                                                            ?.permission_users.map((user, index) => (
                                                                <li
                                                                    key={index}
                                                                    className={`d-flex justify-content-between align-items-center px-3 py-2 mb-2 rounded ${
                                                                        index % 2 === 0 ? "bg-light" : "bg-white"
                                                                    }`}
                                                                    style={{fontSize: "0.9rem"}}
                                                                >
                                                                    <div className="d-flex align-items-center flex-grow-1">
                                                          <span className="me-auto">
                                                            {user.username} {user.first_name} {user.last_name}
                                                          </span>
                                                                        <span className="mx-2 text-muted">
                                                                            {user.is_active ? t("Active") : t("Not Active")}
                                                                        </span>

                                                                        {loggedInUser?.id !== user?.id && (
                                                                            <Switch
                                                                                defaultChecked={user.is_active}
                                                                                disabled={
                                                                                    !perm?.permissions?.includes(
                                                                                        "modify_permissions"
                                                                                    )
                                                                                }
                                                                                onLabel={t("Active")}
                                                                                offLabel={t("Not Active")}
                                                                                onChange={(event) =>
                                                                                    handleUserActiveChange(event, user.id)
                                                                                }
                                                                            />
                                                                        )}
                                                                    </div>
                                                                    {perm?.permissions?.includes("modify_permissions") && (
                                                                        <FaTrash
                                                                            onClick={(event) =>
                                                                                handleDeleteUser(event, user.id)
                                                                            }
                                                                            color={"#fd6358"}
                                                                            size={18}
                                                                            title={`${t(
                                                                                "Remove User from"
                                                                            )} ${selectedProfile}`}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                marginLeft: "1rem",
                                                                            }}
                                                                        />
                                                                    )}
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )}


                                </div>
                            </div>
                        )}
                    </div>

                </div>
            )}
        </>
    );
};

export default PermissionProfiles;
