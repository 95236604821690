import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLanguageStore } from "../../hooks/useLanguage";
import { usePermissions } from "../../hooks/PermissionContext";
import { AddVisitModal } from "./AddVisitModal";
import {
  fetchMemberDelete,
  fetchMemberGroups,
  fetchGroups,
} from "../../libs/api";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Table,
  Image,
  Card,
  Tabs,
  Tab,
  Container,
  ButtonGroup,
  ListGroup,
} from "react-bootstrap";
import { FaSave, FaTrash, FaUser } from "react-icons/fa";
import "../../styles/membermodal.css";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { desktopMediaQuery } from "../../libs/consts";

const languages = [
  { label: "Afrikaans", value: "af" },
  { label: "English", value: "en" },
];

const DEFAULT_MEMBER = {
  FullNames: "",
  Names: "",
  Surname: "",
  BirthDate: "",
  Initials: "",
  IdNo: "",
  notesByType: {},
  selectedNoteType: "General",
  title: "",
  picture: null,
  pictureUrl: "",
  profession: "null",
  member_email: "",
  phone_tel: "",
  phone_cell: "",
  employer: "",
  language: "",
};

export const MemberModal = (props) => {
  const {
    show,
    onHide,
    selectedMember,
    theme,
    memberVisits,
    saveEditedMember,
    selectedFamilyChanged,
    setSelectedMember,
    selectedMemberChanged,
    titles,
    professions,
    noteTypes,
    user,
  } = props;

  const { t } = useLanguageStore();
  const permissions = usePermissions();
  const isDesktop = useMediaQuery(desktopMediaQuery);

  const [editedMember, setEditedMember] = useState(DEFAULT_MEMBER);
  const [checked, setChecked] = useState(languages[0].value);
  const [disabled, setDisabled] = useState(true);
  const [addVisitModal, setAddVisitModal] = useState(false);
  const [showVisitNotesIndex, setShowVisitNotesIndex] = useState(null);
  const [groups, setGroups] = useState([]);
  const [memberGroups, setMemberGroups] = useState([]);

  useEffect(() => {
    if (selectedMember) {
      fetchGroups()
        .then((data) => setGroups(data.groups))
        .catch((error) => console.error(error));
      fetchMemberGroups(selectedMember?.id)
        .then((data) => setMemberGroups(data.data))
        .catch((error) => console.error(error));
      const updatedMember = {
        ...selectedMember,
        notesByType: {},
        selectedNoteType: "General",
      };
      if (selectedMember.notes) {
        updatedMember.notesByType = selectedMember.notes.reduce((acc, note) => {
          acc[note.type.name] = note.note_text;
          return acc;
        }, {});
      }
      if (
        selectedMember.picture &&
        typeof selectedMember.picture === "string"
      ) {
        updatedMember.pictureUrl = selectedMember.picture;
        updatedMember.picture = null;
      }
      setEditedMember(updatedMember);
      if (selectedMember.language) {
        setChecked(selectedMember.language);
      }
    } else {
      setEditedMember({
        FullNames: "",
        Names: "",
        Surname: "",
        BirthDate: "",
        Initials: "",
        IdNo: "",
        notesByType: {},
        selectedNoteType: "General",
        title: "",
        picture: null,
        pictureUrl: "",
        profession: "null",
        member_email: "",
        phone_tel: "",
        phone_cell: "",
        employer: "",
        language: "",
      });
    }
    setShowVisitNotesIndex(null);
  }, [selectedMember, memberVisits]);

  const memberValueChanged = (e) => {
    let { name, value } = e.target;
    if ((name === "title" || name === "profession") && !isNaN(value)) {
      value = Number(value);
    }
    setEditedMember({ ...editedMember, [name]: value });
  };

  const noteContentChanged = (e) => {
    const { value } = e.target;
    if (editedMember.selectedNoteType) {
      setEditedMember({
        ...editedMember,
        notesByType: {
          ...editedMember.notesByType,
          [editedMember.selectedNoteType]: value,
        },
      });
    } else {
      toast.error(t("Please select a note type first"));
    }
  };

  const isMemberUnchanged = () => {
    return JSON.stringify(editedMember) === JSON.stringify(selectedMember);
  };

  const saveMemberClicked = () => {
    let family_id = selectedMember?.family_id;
    // Convert notesByType to notes string if required by the API.
    const memberData = {
      ...editedMember,
      notes: JSON.stringify(editedMember.notesByType),
    };
    saveEditedMember(memberData).then((data) => {
      if (data?.success) {
        toast.success(t("Member saved successfully"));
        selectedFamilyChanged(family_id);
        onHide();
        setSelectedMember(null);
      } else {
        toast.error(t("Failed to save member"));
      }
    });
  };

  const onHideAddVisitModal = () => {
    setAddVisitModal(false);
    selectedMemberChanged(selectedMember);
  };

  const handleDeleteMember = (member_id) => {
    let family_id = selectedMember?.family_id;
    if (window.confirm("Are you sure you want to delete this member?")) {
      fetchMemberDelete(member_id).then((data) => {
        if (data?.success) {
          toast.success(t("Member deleted successfully"));
          selectedFamilyChanged(family_id);
          onHide();
          setSelectedMember(null);
        } else {
          toast.error(t("Failed to delete member"));
        }
      });
    }
  };

  const handleLanguageChange = (e) => {
    const value = e.target.value;
    setChecked(value);
    setEditedMember({ ...editedMember, language: value });
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setEditedMember({ ...editedMember, picture: e.target.files[0] });
    }
  };

  return (
    <>
      <AddVisitModal
        showVisit={addVisitModal}
        onHideVisit={onHideAddVisitModal}
        setAddVisitModal={setAddVisitModal}
        addVisitModal={addVisitModal}
        member_id={selectedMember?.id}
        theme={theme}
        selectedMember={selectedMember}
        user={user}
      />

      <Modal
        size="xl"
        fullscreen={!isDesktop}
        show={show}
        onHide={onHide}
        centered
        backdrop="static"
        className={`modal-custom-backdrop-${theme} p-0`}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Member Details")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0" style={{ height: "76vh" }}>
          <Row className="h-100 m-0">
            <Col
              lg={3}
              className="d-none d-lg-block p-0 h-100 overflow-auto bg-body-tertiary"
            >
              <QuickInfo {...{ t, editedMember }} />
            </Col>
            <Col lg={9} className="p-0 h-100 overflow-auto">
              <Tabs defaultActiveKey="info" className="">
                <Tab eventKey="info" title="Info">
                  <InfoTab
                    {...{
                      editedMember,
                      handleFileChange,
                      memberValueChanged,
                      checked,
                      disabled,
                      setDisabled,
                      saveMemberClicked,
                      isMemberUnchanged,
                      handleLanguageChange,
                      noteContentChanged,
                      permissions,
                      titles,
                      professions,
                      noteTypes,
                      t,
                    }}
                  />
                </Tab>
                <Tab eventKey="groups" title="Groups">
                  <GroupsTab {...{ groups, memberGroups }} />
                </Tab>
                <Tab eventKey="visits" title="Visits">
                  <VisitsTab
                    {...{
                      memberVisits,
                      editedMember,
                      setAddVisitModal,
                      showVisitNotesIndex,
                      setShowVisitNotesIndex,
                      permissions,
                      t,
                    }}
                  />
                </Tab>
                <Tab eventKey="notes" title="Notes">
                  <Container className="p-3">Under construction</Container>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

const QuickInfo = ({ t, editedMember }) => {
  return (
    <Container className="p-4 d-flex flex-column overflow-auto h-100">
      <div className="d-flex flex-column align-items-center">
        <FaUser
          style={{
            width: "100px",
            height: "100px",
            marginBottom: "10px",
            color: "rgba(124,116,200,0.1)",
          }}
        />
      </div>
      <p>
        <strong>{t("Title")}:</strong> {editedMember.title}
      </p>
      <p>
        <strong>{t("Full Names")}:</strong> {editedMember.FullNames}{" "}
        {editedMember.Surname}
      </p>
      <p>
        <strong>{t("Date of Birth")}:</strong> {editedMember.BirthDate}
      </p>
      <p>
        <strong>{t("Email")}:</strong> {editedMember.member_email}
      </p>
      <p>
        <strong>{t("Cellphone")}:</strong> {editedMember.phone_cell}
      </p>
      <p>
        <strong>{t("Profession")}:</strong> {editedMember.profession} at{" "}
        {editedMember.employer}
      </p>
    </Container>
  );
};

const InfoTab = ({
  editedMember,
  handleFileChange,
  memberValueChanged,
  checked,
  disabled,
  setDisabled,
  saveMemberClicked,
  isMemberUnchanged,
  handleLanguageChange,
  noteContentChanged,
  permissions,
  titles,
  professions,
  noteTypes,
  t,
}) => {
  return (
    <Container className="p-3">
      <Form>
        <Row className="mb-3 align-items-center">
          <Col xs="auto">
            <FaUser
              style={{
                width: "100px",
                height: "100px",
                marginBottom: "10px",
                color: "rgba(124,116,200,0.1)",
              }}
            />
          </Col>
          <Col>
            {permissions?.permissions?.includes("modify_members") && (
              <Form.Group controlId="formPicture" className="mb-2">
                <Form.Label>{t("Upload Photo")}</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} />
              </Form.Group>
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formTitle">
              <Form.Label>{t("Title")}</Form.Label>
              <Form.Select
                name="title"
                value={editedMember.title || ""}
                onChange={memberValueChanged}
                disabled={!permissions?.permissions?.includes("modify_members")}
              >
                {titles?.map((title, index) => (
                  <option key={index} value={title.id}>
                    {t(title.name)}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formFullNames">
              <Form.Label>{t("Full Names")}</Form.Label>
              <Form.Control
                type="text"
                name="FullNames"
                value={editedMember.FullNames || ""}
                onChange={memberValueChanged}
                readOnly={!permissions?.permissions?.includes("modify_members")}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formSurname">
              <Form.Label>{t("Surname")}</Form.Label>
              <Form.Control
                type="text"
                name="Surname"
                value={editedMember.Surname || ""}
                onChange={memberValueChanged}
                readOnly={!permissions?.permissions?.includes("modify_members")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formNames">
              <Form.Label>{t("Names")}</Form.Label>
              <Form.Control
                type="text"
                name="Names"
                value={editedMember.Names || ""}
                onChange={memberValueChanged}
                readOnly={!permissions?.permissions?.includes("modify_members")}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formInitials">
              <Form.Label>{t("Initials")}</Form.Label>
              <Form.Control
                type="text"
                name="Initials"
                value={editedMember.Initials || ""}
                onChange={memberValueChanged}
                readOnly={!permissions?.permissions?.includes("modify_members")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formBirthDate">
              <Form.Label>{t("Date of Birth")}</Form.Label>
              <Form.Control
                type="date"
                name="BirthDate"
                value={editedMember.BirthDate || ""}
                onChange={memberValueChanged}
                readOnly={!permissions?.permissions?.includes("modify_members")}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formIdNo">
              <Form.Label>{t("ID Number")}</Form.Label>
              <Form.Control
                type="text"
                name="IdNo"
                value={editedMember.IdNo || ""}
                onChange={memberValueChanged}
                readOnly={!permissions?.permissions?.includes("modify_members")}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formEmail">
              <Form.Label>{t("Email")}</Form.Label>
              <Form.Control
                type="text"
                name="member_email"
                value={editedMember.member_email || ""}
                onChange={memberValueChanged}
                readOnly={!permissions?.permissions?.includes("modify_members")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formPhoneTel">
              <Form.Label>{t("Home Telephone")}</Form.Label>
              <Form.Control
                type="text"
                name="phone_tel"
                value={editedMember.phone_tel || ""}
                onChange={memberValueChanged}
                readOnly={!permissions?.permissions?.includes("modify_members")}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formPhoneCell">
              <Form.Label>{t("Cellphone")}</Form.Label>
              <Form.Control
                type="text"
                name="phone_cell"
                value={editedMember.phone_cell || ""}
                onChange={memberValueChanged}
                readOnly={!permissions?.permissions?.includes("modify_members")}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formProfession">
              <Form.Label>{t("Profession")}</Form.Label>
              <Form.Select
                name="profession"
                value={editedMember.profession || ""}
                onChange={memberValueChanged}
                disabled={!permissions?.permissions?.includes("modify_members")}
              >
                {professions?.map((profession, index) => (
                  <option key={index} value={profession?.name}>
                    {profession?.name || ""}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formEmployer">
              <Form.Label>{t("Employer")}</Form.Label>
              <Form.Control
                type="text"
                name="employer"
                value={editedMember.employer || ""}
                onChange={memberValueChanged}
                readOnly={!permissions?.permissions?.includes("modify_members")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3 align-items-center">
          <Col xs="auto">
            {!disabled && (
              <Form.Check
                inline
                label={t("English")}
                type="radio"
                value="en"
                checked={checked === "en"}
                onChange={handleLanguageChange}
              />
            )}
            {!disabled && (
              <Form.Check
                inline
                label={t("Afrikaans")}
                type="radio"
                value="af"
                checked={checked === "af"}
                onChange={handleLanguageChange}
              />
            )}
          </Col>
          <Col>
            <span>
              {t("Preferred Language")}:{" "}
              <strong>
                {checked === "en" ? t("English") : t("Afrikaans")}
              </strong>
            </span>
          </Col>
          {permissions?.permissions?.includes("modify_member") && (
            <Col xs="auto">
              <Button
                variant="secondary"
                onClick={() => setDisabled(!disabled)}
              >
                {disabled ? t("Change Preferred Language") : t("Set Language")}
              </Button>
            </Col>
          )}
        </Row>
        {permissions?.permissions?.includes("view_notes") && (
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formSelectedNoteType">
                <Form.Label>{t("Select Note Type:")}</Form.Label>
                <Form.Select
                  name="selectedNoteType"
                  value={editedMember.selectedNoteType || ""}
                  onChange={memberValueChanged}
                >
                  {noteTypes?.map((notetype, index) => (
                    <option key={index} value={notetype.id}>
                      {notetype.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="formNotes" className="mt-2">
                {permissions?.permissions?.includes("modify_notes") ? (
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="notes"
                    style={{ resize: "none" }}
                    value={
                      editedMember.selectedNoteType === "General"
                        ? editedMember.notesByType["General"] || ""
                        : editedMember.notesByType[
                            editedMember.selectedNoteType
                          ] || ""
                    }
                    onChange={noteContentChanged}
                  />
                ) : (
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="notes"
                    style={{ resize: "none" }}
                    readOnly
                    value={
                      editedMember.selectedNoteType === "General"
                        ? editedMember.notesByType["General"] || ""
                        : editedMember.notesByType[
                            editedMember.selectedNoteType
                          ] || ""
                    }
                  />
                )}
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Button
              variant={isMemberUnchanged() ? "secondary" : "primary"}
              className="w-100"
              onClick={saveMemberClicked}
            >
              <FaSave className="me-2" />
              {t("Save")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

const GroupsTab = ({ groups, memberGroups }) => {
  const [showAll, setShowAll] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const isMemberOfGroup = (groupId) => {
    return memberGroups.some((memberGroup) => memberGroup.id === groupId);
  };

  return (
    <Container className="p-3" fluid="xs">
      <div className="d-flex flex-row justify-content-end justify-content-lg-between mb-3">
        <ButtonGroup className="me-2">
          <Button
            variant={showAll ? "primary" : ""}
            onClick={() => setShowAll(true)}
          >
            Show All
          </Button>
          <Button
            variant={showAll ? "" : "primary"}
            onClick={() => setShowAll(false)}
          >
            Show Belonging
          </Button>
        </ButtonGroup>
        <Button variant="warning" className="ms-auto me-2">
          Leave
        </Button>
        <Button variant="primary">Save</Button>
      </div>
      <ListGroup>
        {(showAll ? groups : memberGroups).map((group) => (
          <ListGroup.Item
            key={group.id}
            active={selectedGroups.includes(group.id)}
            onClick={() => {
              if (selectedGroups.includes(group.id)) {
                setSelectedGroups(
                  selectedGroups.filter((id) => id !== group.id)
                );
              } else {
                setSelectedGroups([...selectedGroups, group.id]);
              }
            }}
            style={{ cursor: "pointer" }}
            // active={showAll && isMemberOfGroup(group.id)}
          >
            {group.name}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  );
};

const VisitsTab = ({
  memberVisits,
  editedMember,
  setAddVisitModal,
  showVisitNotesIndex, // since note text is truncated, we could still use this to show the full note
  setShowVisitNotesIndex,
  permissions,
  t,
}) => {
  return (
    <Container className="p-3">
      {permissions?.permissions?.includes("add_visits") && (
        <div className="text-end mb-3">
          <Button onClick={() => setAddVisitModal(true)} variant="primary">
            {t("Add Visit")}
          </Button>
        </div>
      )}
      {memberVisits?.filter(
        (visit) => visit.visited_user.id === editedMember.id
      ).length === 0 ? (
        <div className="text-center text-muted">
          <h4>{t("No Visits")}</h4>
        </div>
      ) : (
        <Table bordered responsive>
          <thead>
            <tr>
              <th>{t("Visit Date")}</th>
              <th>{t("Visited by")}</th>
              <th>{t("Visit Reason")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {memberVisits
              ?.filter((visit) => visit.visited_user.id === editedMember.id)
              .map((visit, visitIndex) => (
                <tr key={visitIndex}>
                  <td>{visit?.dt_created}</td>
                  <td>
                    <span className="badge bg-warning-lt">
                      {visit?.visiting_user?.username}
                    </span>
                  </td>
                  <td>{visit?.reason?.name}</td>
                  <td>
                    {permissions?.permissions?.includes("view_visit_notes") && (
                      <span className="text-truncate">{visit?.notes}</span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

const DeleteButton = ({
  t,
  permissions,
  handleDeleteMember,
  selectedMember,
}) => {
  if (!permissions?.permissions?.includes("delete_members")) return null;
  return (
    <Button
      variant="danger"
      onClick={() => handleDeleteMember(selectedMember.id)}
    >
      <FaTrash /> {t("Delete this member")}
    </Button>
  );
};

const UserAvatar = () => {
  return {
    /* {editedMember.picture ? (
              <Image
                roundedCircle
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  marginBottom: "10px",
                }}
                src={URL.createObjectURL(editedMember.picture)}
                alt="Member"
              />
            ) : editedMember.pictureUrl ? (
              <Image
                roundedCircle
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  marginBottom: "10px",
                }}
                src={editedMember.pictureUrl}
                alt={t("Member")}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "";
                }}
              />
            ) : (
              <FaUser
                style={{
                  width: "100px",
                  height: "100px",
                  marginBottom: "10px",
                  color: "rgba(124,116,200,0.1)",
                }}
              />
            )} */
  };
};
