import {Form, Field, FormElement, FieldWrapper} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
const AddProfileModalBody = ({ onSubmit,  theme, t }) => {
  return (
    <Form onSubmit={onSubmit}
          render={(formRenderProps) => (
    <FormElement
      style={{
        maxWidth: 650,
        color: theme === "dark" ? "white" : "black",
      }}
    >

          <FieldWrapper>
            <fieldset>
              <div
                className="k-form-field-wrap"
                style={{
                  color: theme === "dark" ? "rgb(116,116,126)" : "",
                }}
              >
                <Field
                  name={"profileName"}
                  component={Input}
                  labelClassName={"k-form-label"}
                  label={t("...New Profile Name with default permissions")}
                />
              </div>
            </fieldset>
            <div className="k-form-buttons">
              <Button disabled={!formRenderProps.allowSubmit}>
                {t("Submit")}
              </Button>
            </div>
          </FieldWrapper>
        </FormElement>
      )}
    />
    );
}
export default AddProfileModalBody;

